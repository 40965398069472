<template>
    <div>
        <transition name="fade" appear>
            <div class="sending-link">
               <transition name="fade" mode="out-in">
                    <FormulateForm v-if="!sending" class="sending-link-form">
                        <div class="grid-dashboard grid-dashboard-form">
                            <div class="grid-item">
                                <FormulateInput
                                    name="login"
                                    type="text"
                                    label="Введите логин"
                                    class="big"
                                    v-changeFocusBlur
                                    autocomplete="off"
                                />
                            </div>
                            <div class="grid-item grid-dashboard recapcha grid-recapcha grid-column-2">
                                <!-- возможно лучше компонентом сделать капчу -->
                                <div class="recapcha-img">
                                    код
                                </div>
                                <FormulateInput
                                    name="login"
                                    type="text"
                                    label="Защитный код"
                                    class="big"
                                    v-changeFocusBlur
                                    autocomplete="off"
                                />
                            </div>
                            <div class="grid-item grid-dashboard">
                                <div class="grid-item fill">
                                    <button class="btn-big btn-text primary"
                                            @click="sendingLink"
                                    >
                                        Отправить ссылку на восстановление
                                    </button>
                                </div>
                                <div class="grid-item fill">
                                    <router-link
                                        to="/"
                                        class="btn-big btn-text link-inline"
                                        tag="button"
                                    >
                                        Вернуться к авторизации
                                    </router-link>
                                </div>

                            </div>
                        </div>
                    </FormulateForm> 

                    <div v-else class="sending-link-message"> 
                        <p>По указанному логину не найдена учетная запись. Проверьте правильность написания логина или обратитесь к куратору.</p>
                        <router-link
                            class="btn-big btn-text primary"
                            to="/"
                            tag="button"
                        >
                            Вернуться к авторизации
                        </router-link>
                    </div>

                    <!-- <div v-else class="sending-link-success">
                        <p>
                            Ссылка для восстановление пароля отправлена на указанный 
                            e-mail — <span>{{ email }}</span>
                            и на номер телефона — <span>{{ mobile }}</span>
                        </p>
                    </div> -->
               </transition>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'sending-link',
    data() {
        return {
            email: 'k.p • • • • • • • • • • @evonpf.ru',
            mobile: '+7987 • • • 1245',
            sending: false
        }
    },
    methods: {
        sendingLink() {
            this.sending = !this.sending;
        }
    }
}
</script>
